import * as i0 from '@angular/core';
import { InjectionToken, makeEnvironmentProviders, inject, PLATFORM_ID, Injectable, signal, computed, ElementRef, afterRenderEffect, SecurityContext, Directive, input, booleanAttribute, output, NgModule } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, firstValueFrom, filter, switchMap, tap, EMPTY, catchError, throwError, forkJoin, map, from } from 'rxjs';
const HIGHLIGHT_OPTIONS = new InjectionToken('HIGHLIGHT_OPTIONS');
function provideHighlightOptions(options) {
  return makeEnvironmentProviders([{
    provide: HIGHLIGHT_OPTIONS,
    useValue: options
  }]);
}
var LoaderErrors;
(function (LoaderErrors) {
  LoaderErrors["FULL_WITH_CORE_LIBRARY_IMPORTS"] = "The full library and the core library were imported, only one of them should be imported!";
  LoaderErrors["FULL_WITH_LANGUAGE_IMPORTS"] = "The highlighting languages were imported they are not needed!";
  LoaderErrors["CORE_WITHOUT_LANGUAGE_IMPORTS"] = "The highlighting languages were not imported!";
  LoaderErrors["LANGUAGE_WITHOUT_CORE_IMPORTS"] = "The core library was not imported!";
  LoaderErrors["NO_FULL_AND_NO_CORE_IMPORTS"] = "Highlight.js library was not imported!";
})(LoaderErrors || (LoaderErrors = {}));
class HighlightLoader {
  constructor() {
    this.document = inject(DOCUMENT);
    this.isPlatformBrowser = isPlatformBrowser(inject(PLATFORM_ID));
    this.options = inject(HIGHLIGHT_OPTIONS, {
      optional: true
    });
    // Stream that emits when hljs library is loaded and ready to use
    this._ready = new BehaviorSubject(null);
    this.ready = firstValueFrom(this._ready.asObservable().pipe(filter(hljs => !!hljs)));
    if (this.isPlatformBrowser) {
      // Check if hljs is already available
      if (this.document.defaultView['hljs']) {
        this._ready.next(this.document.defaultView['hljs']);
      } else {
        // Load hljs library
        this._loadLibrary().pipe(switchMap(hljs => {
          if (this.options?.lineNumbersLoader) {
            // Make hljs available on window object (required for the line numbers library)
            this.document.defaultView['hljs'] = hljs;
            // Load line numbers library
            return this.loadLineNumbers().pipe(tap(plugin => {
              plugin.activateLineNumbers();
              this._ready.next(hljs);
            }));
          } else {
            this._ready.next(hljs);
            return EMPTY;
          }
        }), catchError(e => {
          console.error('[HLJS] ', e);
          this._ready.error(e);
          return EMPTY;
        })).subscribe();
      }
      // Load highlighting theme
      if (this.options?.themePath) {
        this.loadTheme(this.options.themePath);
      }
    }
  }
  /**
   * Lazy-Load highlight.js library
   */
  _loadLibrary() {
    if (this.options) {
      if (this.options.fullLibraryLoader && this.options.coreLibraryLoader) {
        return throwError(() => LoaderErrors.FULL_WITH_CORE_LIBRARY_IMPORTS);
      }
      if (this.options.fullLibraryLoader && this.options.languages) {
        return throwError(() => LoaderErrors.FULL_WITH_LANGUAGE_IMPORTS);
      }
      if (this.options.coreLibraryLoader && !this.options.languages) {
        return throwError(() => LoaderErrors.CORE_WITHOUT_LANGUAGE_IMPORTS);
      }
      if (!this.options.coreLibraryLoader && this.options.languages) {
        return throwError(() => LoaderErrors.LANGUAGE_WITHOUT_CORE_IMPORTS);
      }
      if (this.options.fullLibraryLoader) {
        return this.loadFullLibrary();
      }
      if (this.options.coreLibraryLoader && this.options.languages && Object.keys(this.options.languages).length) {
        return this.loadCoreLibrary().pipe(switchMap(hljs => this._loadLanguages(hljs)));
      }
    }
    return throwError(() => LoaderErrors.NO_FULL_AND_NO_CORE_IMPORTS);
  }
  /**
   * Lazy-load highlight.js languages
   */
  _loadLanguages(hljs) {
    const languages = Object.entries(this.options.languages).map(([langName, langLoader]) => importModule(langLoader()).pipe(tap(langFunc => hljs.registerLanguage(langName, langFunc))));
    return forkJoin(languages).pipe(map(() => hljs));
  }
  /**
   * Import highlight.js core library
   */
  loadCoreLibrary() {
    return importModule(this.options.coreLibraryLoader());
  }
  /**
   * Import highlight.js library with all languages
   */
  loadFullLibrary() {
    return importModule(this.options.fullLibraryLoader());
  }
  /**
   * Import line numbers library
   */
  loadLineNumbers() {
    return from(this.options.lineNumbersLoader());
  }
  /**
   * Reload theme styles
   */
  setTheme(path) {
    if (this.isPlatformBrowser) {
      if (this._themeLinkElement) {
        this._themeLinkElement.href = path;
      } else {
        this.loadTheme(path);
      }
    }
  }
  /**
   * Load theme
   */
  loadTheme(path) {
    this._themeLinkElement = this.document.createElement('link');
    this._themeLinkElement.href = path;
    this._themeLinkElement.type = 'text/css';
    this._themeLinkElement.rel = 'stylesheet';
    this._themeLinkElement.media = 'screen,print';
    this.document.head.appendChild(this._themeLinkElement);
  }
  static {
    this.ɵfac = function HighlightLoader_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HighlightLoader)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HighlightLoader,
      factory: HighlightLoader.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HighlightLoader, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
/**
 * Map loader response to module object
 */
const importModule = moduleLoader => {
  return from(moduleLoader).pipe(filter(module => !!module?.default), map(module => module.default));
};
class HighlightJS {
  constructor() {
    this.loader = inject(HighlightLoader);
    this.options = inject(HIGHLIGHT_OPTIONS, {
      optional: true
    });
    this.hljsSignal = signal(null);
    this.hljs = computed(() => this.hljsSignal());
    // Load highlight.js library on init
    this.loader.ready.then(hljs => {
      this.hljsSignal.set(hljs);
      if (this.options?.highlightOptions) {
        // Set global config if present
        hljs.configure(this.options.highlightOptions);
      }
    });
  }
  /**
   * Core highlighting function. Accepts the code to highlight (string) and a list of options (object)
   */
  async highlight(code, options) {
    const hljs = await this.loader.ready;
    return hljs.highlight(code, options);
  }
  /**
   * Highlighting with language detection.
   */
  async highlightAuto(value, languageSubset) {
    const hljs = await this.loader.ready;
    return hljs.highlightAuto(value, languageSubset);
  }
  /**
   * Applies highlighting to a DOM node containing code.
   * This function is the one to use to apply highlighting dynamically after page load or within initialization code of third-party JavaScript frameworks.
   * The function uses language detection by default but you can specify the language in the class attribute of the DOM node. See the scopes reference for all available language names and scopes.
   */
  async highlightElement(element) {
    const hljs = await this.loader.ready;
    hljs.highlightElement(element);
  }
  /**
   * Applies highlighting to all elements on a page matching the configured cssSelector. The default cssSelector value is 'pre code',
   * which highlights all code blocks. This can be called before or after the page’s onload event has fired.
   */
  async highlightAll() {
    const hljs = await this.loader.ready;
    hljs.highlightAll();
  }
  /**
   * @deprecated in version 12
   * Configures global options:
   */
  async configure(config) {
    const hljs = await this.loader.ready;
    hljs.configure(config);
  }
  /**
   * Adds new language to the library under the specified name. Used mostly internally.
   * The function is passed the hljs object to be able to use common regular expressions defined within it.
   */
  async registerLanguage(languageName, languageDefinition) {
    const hljs = await this.loader.ready;
    hljs.registerLanguage(languageName, languageDefinition);
  }
  /**
   * Removes a language and its aliases from the library. Used mostly internally
   */
  async unregisterLanguage(languageName) {
    const hljs = await this.loader.ready;
    hljs.unregisterLanguage(languageName);
  }
  /**
   * Adds new language alias or aliases to the library for the specified language name defined under languageName key.
   */
  async registerAliases(alias, {
    languageName
  }) {
    const hljs = await this.loader.ready;
    hljs.registerAliases(alias, {
      languageName
    });
  }
  /**
   * @return The languages names list.
   */
  async listLanguages() {
    const hljs = await this.loader.ready;
    return hljs.listLanguages();
  }
  /**
   * Looks up a language by name or alias.
   */
  async getLanguage(name) {
    const hljs = await this.loader.ready;
    return hljs.getLanguage(name);
  }
  /**
   * Enables safe mode. This is the default mode, providing the most reliable experience for production usage.
   */
  async safeMode() {
    const hljs = await this.loader.ready;
    hljs.safeMode();
  }
  /**
   * Enables debug/development mode.
   */
  async debugMode() {
    const hljs = await this.loader.ready;
    hljs.debugMode();
  }
  /**
   * Display line numbers
   */
  async lineNumbersBlock(el, options) {
    const hljs = await this.loader.ready;
    if (hljs.lineNumbersBlock) {
      hljs.lineNumbersBlock(el, options);
    }
  }
  static {
    this.ɵfac = function HighlightJS_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HighlightJS)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HighlightJS,
      factory: HighlightJS.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HighlightJS, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();

/**
 * Enable usage of the library together with "trusted-types" HTTP Content-Security-Policy (CSP)
 *
 * Can be added to angular.json -> serve -> options -> headers to try it out in DEV mode
 * "Content-Security-Policy": "trusted-types ngx-highlightjs; require-trusted-types-for 'script'"
 *
 * Read more...
 * Angular Security: https://angular.io/guide/security#enforcing-trusted-types
 * Trusted Types: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy/trusted-types
 */
let policy;
function getPolicy() {
  if (!policy) {
    try {
      policy = window?.trustedTypes?.createPolicy('ngx-highlightjs', {
        createHTML: s => s
      });
    } catch {
      // trustedTypes.createPolicy throws if called with a name that is
      // already registered, even in report-only mode. Until the API changes,
      // catch the error not to break the applications functionally. In such
      // cases, the code will fall back to using strings.
    }
  }
  return policy;
}
function trustedHTMLFromStringBypass(html) {
  return getPolicy()?.createHTML(html) || html;
}
class HighlightBase {
  constructor() {
    this._hljs = inject(HighlightJS);
    this._nativeElement = inject(ElementRef).nativeElement;
    this._sanitizer = inject(DomSanitizer);
    afterRenderEffect({
      write: () => {
        const code = this.code();
        // Set code text before highlighting
        this.setTextContent(code || '');
        if (code) {
          this.highlightElement(code);
        }
      }
    });
    afterRenderEffect({
      write: () => {
        const res = this.highlightResult();
        this.setInnerHTML(res?.value);
        // Forward highlight response to the highlighted output
        this.highlighted.emit(res);
      }
    });
  }
  setTextContent(content) {
    requestAnimationFrame(() => this._nativeElement.textContent = content);
  }
  setInnerHTML(content) {
    requestAnimationFrame(() => this._nativeElement.innerHTML = trustedHTMLFromStringBypass(this._sanitizer.sanitize(SecurityContext.HTML, content) || ''));
  }
  static {
    this.ɵfac = function HighlightBase_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HighlightBase)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: HighlightBase
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HighlightBase, [{
    type: Directive
  }], () => [], null);
})();
class Highlight extends HighlightBase {
  constructor() {
    super(...arguments);
    // Code to highlight
    this.code = input(null, {
      alias: 'highlight'
    });
    // Highlighted result
    this.highlightResult = signal(null);
    // The language name highlight only one language.
    this.language = input.required();
    // An optional flag, when set to true it forces highlighting to finish even in case of detecting
    // illegal syntax for the language instead of throwing an exception.
    this.ignoreIllegals = input(undefined, {
      transform: booleanAttribute
    });
    // Stream that emits when code string is highlighted
    this.highlighted = output();
  }
  async highlightElement(code) {
    const res = await this._hljs.highlight(code, {
      language: this.language(),
      ignoreIllegals: this.ignoreIllegals()
    });
    this.highlightResult.set(res);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵHighlight_BaseFactory;
      return function Highlight_Factory(__ngFactoryType__) {
        return (ɵHighlight_BaseFactory || (ɵHighlight_BaseFactory = i0.ɵɵgetInheritedFactory(Highlight)))(__ngFactoryType__ || Highlight);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: Highlight,
      selectors: [["", "highlight", ""]],
      hostVars: 2,
      hostBindings: function Highlight_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("hljs", true);
        }
      },
      inputs: {
        code: [1, "highlight", "code"],
        language: [1, "language"],
        ignoreIllegals: [1, "ignoreIllegals"]
      },
      outputs: {
        highlighted: "highlighted"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: HighlightBase,
        useExisting: Highlight
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Highlight, [{
    type: Directive,
    args: [{
      selector: '[highlight]',
      providers: [{
        provide: HighlightBase,
        useExisting: Highlight
      }],
      host: {
        '[class.hljs]': 'true'
      }
    }]
  }], null, null);
})();
class HighlightAuto extends HighlightBase {
  constructor() {
    super(...arguments);
    // Code to highlight
    this.code = input(null, {
      alias: 'highlightAuto'
    });
    // Highlighted result
    this.highlightResult = signal(null);
    // An optional array of language names and aliases restricting detection to only those languages.
    // The subset can also be set with configure, but the local parameter overrides the option if set.
    this.languages = input();
    // Stream that emits when code string is highlighted
    this.highlighted = output();
  }
  async highlightElement(code) {
    const res = await this._hljs.highlightAuto(code, this.languages());
    this.highlightResult.set(res);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵHighlightAuto_BaseFactory;
      return function HighlightAuto_Factory(__ngFactoryType__) {
        return (ɵHighlightAuto_BaseFactory || (ɵHighlightAuto_BaseFactory = i0.ɵɵgetInheritedFactory(HighlightAuto)))(__ngFactoryType__ || HighlightAuto);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: HighlightAuto,
      selectors: [["", "highlightAuto", ""]],
      hostVars: 2,
      hostBindings: function HighlightAuto_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("hljs", true);
        }
      },
      inputs: {
        code: [1, "highlightAuto", "code"],
        languages: [1, "languages"]
      },
      outputs: {
        highlighted: "highlighted"
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: HighlightBase,
        useExisting: HighlightAuto
      }]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HighlightAuto, [{
    type: Directive,
    args: [{
      selector: '[highlightAuto]',
      providers: [{
        provide: HighlightBase,
        useExisting: HighlightAuto
      }],
      host: {
        '[class.hljs]': 'true'
      }
    }]
  }], null, null);
})();
class HighlightModule {
  static {
    this.ɵfac = function HighlightModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HighlightModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: HighlightModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HighlightModule, [{
    type: NgModule,
    args: [{
      imports: [Highlight, HighlightAuto],
      exports: [Highlight, HighlightAuto]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { HIGHLIGHT_OPTIONS, Highlight, HighlightAuto, HighlightBase, HighlightJS, HighlightLoader, HighlightModule, provideHighlightOptions };
